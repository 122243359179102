import router from "@/router"
import VueCookies from "vue-cookies"

export default {
  state: {
    user: localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : null,
    token: VueCookies.get("token") || null,
    // token: "helloiamatoken",
    image:
      localStorage.getItem("image") && localStorage.getItem("image") !== "null"
        ? localStorage.getItem("image")
        : null
  },
  getters: {
    user: (state) => state.user,
    token: (state) => state.token,
    image: (state) => state.image
  },
  actions: {
    async setUser({ commit }, { user, token, image }) {
      // commit("SET_USER", user)
      commit("SET_TOKEN", token)
      // commit("SET_IMAGE", image)
      router.push("/")
    },

    updateUser({ commit }, payload) {
      commit("SET_USER", payload)
    },

    removeUser({ commit }) {
      commit("REMOVE_USER")
      router.push("/login")
    },
    updateUserImage({ commit }, payload) {
      commit("SET_IMAGE", payload)
    }
  },
  mutations: {
    SET_USER(state, user) {
      state.user = user
      localStorage.setItem("user", JSON.stringify(user))
    },
    REMOVE_USER(state) {
      state.token = null
      state.user = null
      VueCookies.remove("token")
      localStorage.removeItem("user")
    },
    SET_TOKEN: (state, token) => {
      state.token = token
      VueCookies.set("token", token)
    },
    SET_IMAGE: (state, image) => {
      console.log("====", image)
      state.image = image
      localStorage.setItem("image", image)
    }
  }
}
