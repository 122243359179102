<template>
  <div class="default-layout">
    <TopNavbar />
    <div class="container-fluid px-0">
      <b-row>
        <b-col lg="2">
          <SideMenu />
        </b-col>
        <b-col lg="10" class="view-section-dimensions">
          <slot></slot>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import TopNavbar from "@/components/Layouts/TopNavbar/index.vue"
import SideMenu from "@/components/Layouts/SideMenu/index.vue"
export default {
  components: {
    TopNavbar,
    SideMenu
  }
}
</script>

<style lang="scss" scoped>
@import "./index";
</style>
