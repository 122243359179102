<template>
  <div id="nav" :class="$i18n.locale == 'en' ? '' : 'rtl'">
    <Toast />
    <RouterView />
  </div>
</template>

<script>
export default {
  name: "WadiilyApp",
  created() {
    const htmlDoc = document.documentElement
    htmlDoc.setAttribute("lang", this.$i18n.locale == "ar" ? "ar" : "en")
    htmlDoc.setAttribute("dir", this.$i18n.locale == "ar" ? "rtl" : "ltr")
  }
}
</script>

<style lang="scss">
* {
  &:lang(ar) {
    font-family: "Alexandria", sans-serif;
  }
  &:lang(en) {
    font-family: "Montserrat", sans-serif;
  }
}
i.fa{
  font-family: FontAwesome;
}
body {
  &:lang(ar) {
    text-align: right;
  }
  &:lang(en) {
    text-align: left;
  }
}

*::-webkit-scrollbar {
  width: 0.125rem;
}

*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 4px;
}

*::-webkit-scrollbar-thumb {
  background-color: $color-dark;
  border-radius: 4px;
}
</style>
