import store from "@/store"
const protectedRoutes = [
  {
    path: "/",
    name: "home",
    component: () => import("../views/application/home/index.vue")
  },
  {
    path: "/requests",
    name: "requests",
    component: () => import("../views/application/requests/index.vue")
  },
  {
    path: "/accounts",
    name: "accounts",
    component: () => import("../views/application/accounts/index.vue")
  },
  {
    path: "/drivers",
    name: "drivers",
    component: () => import("../views/application/drivers/index.vue")
  },
  {
    path: "/managers",
    name: "managers",
    component: () => import("../views/application/managers/index.vue")
  },
  {
    path: "/logs",
    name: "logs",
    component: () => import("../views/application/logs/index.vue")
  },
  {
    path: "/transactions",
    name: "transactions",
    component: () => import("../views/application/transactions/index.vue")
  },
  {
    path: "/transportation",
    name: "transportation",
    component: () => import("../views/application/transportation/index.vue")
  },
  {
    path: "/profile",
    name: "profile",
    component: () => import("../views/application/profile/index.vue")
  },
  {
    path: "/wallets",
    name: "wallets",
    component: () => import("../views/application/wallets/index.vue")
  },
  {
    path: "/redeems",
    name: "redeems",
    component: () => import("../views/application/redeems/index.vue")
  },
  {
    path: "/setting",
    name: "setting",
    component: () => import("../views/application/setting/index.vue")
  },
]

export default protectedRoutes
