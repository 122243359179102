<template>
  <div class="side-menu">
    <div class="side-menu__items">
    <h3 class="side-menu__title fs-16">{{ $t("SIDE_MENU.TOOLS") }}</h3>
    <ul class="side-menu__main-items">
      <li
        v-for="(item, idx) in mainItems"
        :key="idx"
        :class="[{ 'active': selectedPath == item.path }]"
      >
        <router-link class="side-menu__main-item-container" :to="item.path">
          <img :src="require(`@/assets/images/icons/${item.image}`)" :class="item.title"/>
          <p class="fs-16">{{ $t(`SIDE_MENU.${item.title}`) }}</p>
        </router-link>
        <ul v-if="item.subItems && item.subItems.length" class="side-menu__sub-items">
          <li v-for="(subItem, idx) in item.subItems" :key="idx" class="sub-item">
            <router-link class="side-menu__sub-item-container" :to="subItem.path">
              <img :src="require(`@/assets/images/icons/${subItem.image}`)" />
              <p class="fs-14">{{ $t(`SIDE_MENU.${subItem.title}`) }}</p>
            </router-link>
          </li>
        </ul>
      </li>
    </ul>
  </div>
    <div class="side-menu__setting">
      <h3 class="side-menu__title fs-16">{{ $t("SIDE_MENU.ACCOUNT") }}</h3>
    <ul class="side-menu__setting-items">
      <li class="setting-item">
        <router-link class="side-menu__setting-item-container" to="/setting">
          <img src="@/assets/images/icons/setting.svg" />
          <p class="fs-16">{{ $t("SIDE_MENU.SETTING") }}</p>
        </router-link>
      </li>
      <li class="setting-item">
        <div class="side-menu__setting-item-container cursor-pointer" @click="logout">
          <img src="@/assets/images/icons/logout.svg" />
          <p class="fs-16">{{ $t("SIDE_MENU.LOGOUT") }}</p>
        </div>
      </li>
    </ul>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex"
export default {
  data() {
    return {
      selectedPath: "",
      mainItems: [
        {
          path: "/",
          image: "general-look.svg",
          title: "GENERAL_LOOK",
          active: false
        },
        {
          path: "accounts",
          image: "managers.svg",
          title: "MANAGERS_ACCOUNTS",
          active: false,
          subItems: [
            {
              path: "drivers",
              image: "circle.svg",
              title: "DRIVERS",
              active: false
            },
            {
              path: "managers",
              image: "circle.svg",
              title: "MANAGERS",
              active: false
            }
          ]
        },
        {
          path: "requests",
          image: "order-date.svg",
          title: "ORDERS_DATES",
          active: false
        },
        // {
        //   path: "complaints",
        //   image: "complaints.svg",
        //   title: "COMPLAINTS",
        //   active: false
        // },
        {
          path: "reports",
          image: "reports.svg",
          title: "REPORTS",
          active: false,
          subItems: [
            {
              path: "transactions",
              image: "circle.svg",
              title: "TRANSACTIONS",
              active: false
            },
            {
              path: "logs",
              image: "circle.svg",
              title: "LOGS",
              active: false
            }
          ]
        },
        {
          path: "transportation",
          image: "reports.svg",
          title: "PRICING",
          active: false,
          subItems: [
            {
              path: "transportation",
              image: "circle.svg",
              title: "TRANSPORTATION",
              active: false
            }
          ]
        },
        {
          path: "wallets",
          image: "wallet.png",
          title: "WALLETS",
          active: false,
          subItems: [
            {
              path: "wallets",
              image: "circle.svg",
              title: "WALLETS",
              active: false
            },
            {
              path: "redeems",
              image: "circle.svg",
              title: "REDEEMS",
              active: false
            }
          ]
        }
      ]
    }
  },
  watch: {
    $route(to, from) {
      if (to.path !== from.path) {
        this.selectedPath = to.path.split("/")[1]
        window.scrollTo(0, top)
      }
    }
  },
  mounted() {
    this.selectedPath = this.$route.path.split("/")[1]
  },
  methods: {
    ...mapActions(["removeUser"]),
    logout() {
      this.removeUser()
    }
  }
}
</script>
<style lang="scss" scoped>
@import "./index.scss";
</style>