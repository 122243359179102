<template>
  <div class="auth-layout">
    <div class="container-fluid px-0">
      <div class="top-navbar">
        <div class="top-navbar__logo">
          <img src="@/assets/images/logo/logo.svg" alt="wadiily loGo" />
        </div>
        <div v-if="isAr" class="top-navbar__language cursor-pointer" @click="changeLang">
        <img src="@/assets/images/icons/en-lang.svg" />
        <h6>English</h6>
        <!-- <img class="arrow-down" src="@/assets/images/icons/arrow-down.svg" /> -->
      </div>
      <div v-else class="top-navbar__language cursor-pointer" @click="changeLang">
        <img src="@/assets/images/icons/ar-lang.svg" />
        <h6>اللغة العربية</h6>
      </div>
      </div>
      <div class="row centered-layout">
        <div class="col-xl-5 col-md-7 my-4">
          <slot name="cardBody" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    isAr() {
      return this.$i18n.locale == "ar"
    }
  },
  methods: {
    changeLang() {
      if (this.$i18n.locale == "en") {
        localStorage.setItem("lang", "ar")
      } else {
        localStorage.setItem("lang", "en")
      }
      window.location = window.location.href
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./index";
</style>
